<template>
  <div>
    <div class="form-group form-group-border-bottom d-flex flex-row padding-10 align-items-center  position-relative">
      <label  v-if="label">
      {{label}}
    </label>
      <select class="form-control-plaintext formselect flex-grow-1" :aria-label="label" :id="name" :name="name" @blur="selectEvent(true, false)" @click="selectEvent(false,false)">
        <option v-for="(option,idx) in options" :key="idx" :value="optionValue(option)" :selected="option.val==field.value">{{optionText(option)}}</option>
      </select>
      <CaretComponent :open="open" />
    </div>
  </div>
</template>
<script>
import CaretComponent from "@/components/CaretComponent";
export default {
  components: {
    CaretComponent
  },
  props: {
    label: String,
    type: String,
    name: String,
    selected: String,
    options: Array,
    simple: {
      type: Boolean,
      default: false
    },
    field: Object
  },
  data() {
    return {
      open: false
    }
  },
  methods: {
    optionValue(option) {
      if (this.simple) {
        return option;
      }
      return option.val;
    },
    optionText(option) {
      if (this.simple) {
        return option;
      }
      return option.text;
    },
    selectEvent(trustedEvent, value) {
      if (trustedEvent) {
        this.open = value;
        return;
      }
      this.open = !this.open;
    }
  }
}
</script>
<style scoped>
.formselect {
  display: block;
  padding: 0.375rem 0.75rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  background-color: inherit;
  border: 0;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  min-height: 39px;
}

.formselect:focus,
.formselect:active {
  border: 0;
}

.caret-position {
  position: relative;
  top: -3px;
}

@media (min-width: 1200px) {
  .search-sort .form-select {
    min-width: 200px;
  }

  .caret-position {
    top: inherit;
  }
}
</style>